import React from 'react'
import { Box, Flex, Link, Text } from '@vtex/store-ui'
import FacebookRounded from 'src/components/icons/FacebookRounded'
import TwitterRounded from 'src/components/icons/TwitterRounded'
import LikeIcon from 'src/components/icons/LikeIcon'

import LikeButton from './LikeButton'
import * as styles from './styles'

interface Props {
  postConfigurations:
    | Maybe<{
        date: Maybe<string>
        description: Maybe<string>
        title: Maybe<string>
        seo: Maybe<{
          description: Maybe<string>
          slug: Maybe<string>
          title: Maybe<string>
          titleTemplate: Maybe<string>
        }>
      }>
    | undefined
}

const PostInfo = ({ postConfigurations }: Props) => {
  return (
    <Box sx={styles.postInfoContainer}>
      <Text as="h1" sx={styles.postInfoTitle}>
        {postConfigurations?.title}
      </Text>
      <Flex sx={styles.postInfoDetails}>
        <Flex>
          <Box className="gradientIcon" />
          <Box className="brand-date">
            <p>Avon</p>
            <span>{postConfigurations?.date}</span>
          </Box>
        </Flex>
        <Box>
          <LikeButton postTitle={postConfigurations?.title as string}>
            <LikeIcon />
          </LikeButton>
          <Link
            href={`https://www.facebook.com/sharer/sharer.php?u=https://www.avon.com.br${postConfigurations?.seo?.slug}`}
            target="blank"
          >
            <FacebookRounded />
          </Link>
          <Link
            href={`https://twitter.com/intent/tweet?text=https://www.avon.com.br${postConfigurations?.seo?.slug}`}
            target="blank"
          >
            <TwitterRounded />
          </Link>
        </Box>
      </Flex>
    </Box>
  )
}

export default PostInfo
