import React, { Fragment } from 'react'
import CommonQuestions from 'src/components/posts/CommonQuestions'
// import RelatedPosts from 'src/components/posts/RelatedPosts'
import type { PilarPageQueryQuery } from 'src/pages/{CmsPilarPage.postConfiguration__postConfiguration__seo__slug}/__generated__/PilarPageQuery.graphql'

interface Props {
  data: PilarPageQueryQuery
}

const BelowTheFold = ({ data }: Props) => {
  // const sections = data.cmsPilarPage?.sections

  // const relatedPosts = sections?.filter(
  //   (section) => section.name === 'Related Post'
  // )

  return (
    <Fragment>
      {/* {relatedPosts && (
        <RelatedPosts contentItem={relatedPosts[0]?.data?.allItems} />
      )} */}
      <CommonQuestions />
    </Fragment>
  )
}

export default BelowTheFold
