import React, { useMemo } from 'react'
import { useGetThumborImageData } from '@vtex/gatsby-plugin-thumbor'
import imagesConf from 'src/images/config'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'
import {
  Box,
  Flex,
  SliderArrowLeft,
  SliderArrowRight,
  useUISlider,
} from '@vtex/store-ui'

import * as styles from './styles'

interface Props {
  banners: Array<{
    href: string
    alt: string
    sources: Array<{
      media: string
      srcSet: string
    }>
  }>
}

const useImages = (item: {
  href: string
  alt: string
  sources: Array<{
    media: string
    srcSet: string
  }>
}) => {
  const getThumborImageData = useGetThumborImageData()

  return useMemo(() => {
    const {
      sources: [desktop, mobile],
    } = item

    const desktopImage = getThumborImageData({
      baseUrl: desktop.srcSet,
      ...imagesConf['departmentBanner.desktop'],
    })

    const mobileImage = getThumborImageData({
      baseUrl: mobile.srcSet,
      ...imagesConf['departmentBanner.mobile'],
    })

    return withArtDirection(mobileImage, [
      {
        media: desktop.media,
        image: desktopImage,
      },
    ])
  }, [item, getThumborImageData])
}

const PostCarousel = ({ banners }: Props) => {
  const {
    items,
    dragHandlers,
    setPreviousPage,
    setNextPage,
    totalPages,
    page,
    setPage,
  } = useUISlider({
    allItems: banners,
    pageSize: 1,
  })

  const [item] = items
  const images = useImages(item)

  return (
    <Box sx={styles.container} {...dragHandlers}>
      <SliderArrowLeft
        sx={{ left: '1rem' }}
        aria-label="Previous Carousel Image"
        onClick={() => setPreviousPage()}
      />
      <Box sx={styles.imageContainer}>
        <GatsbyImage
          image={images}
          alt={item.alt || item.href}
          title={item.alt}
          objectFit="contain"
        />
      </Box>
      <SliderArrowRight
        sx={{ right: '1rem' }}
        aria-label="Next Carousel Image"
        onClick={() => setNextPage()}
      />
      <Flex sx={styles.dotsContainer}>
        {Array.from(Array(totalPages).keys()).map((pageIndex) => {
          const isActive = pageIndex === page

          return (
            <Box
              key={pageIndex}
              role="button"
              title={`Carrossel Imagem ${pageIndex + 1}`}
              onClick={() => setPage(pageIndex)}
              sx={{
                opacity: isActive ? '1' : '0.5',
                width: isActive ? '39px' : '16px',
              }}
            />
          )
        })}
      </Flex>
    </Box>
  )
}

export default PostCarousel
