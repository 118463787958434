import React from 'react'
import { Flex, Heading } from '@vtex/store-ui'

import { questions } from './questions'
import CollapseQuestion from './collapse'
import * as styles from './styles'

const CommonQuestions = () => {
  return (
    <Flex sx={styles.commonQuestionContainer}>
      <Flex sx={styles.commonQuestionWrapper}>
        <Heading as="h4">Perguntas frequentes</Heading>
        <Flex sx={styles.commonQuestionBox}>
          {questions.map(({ question, answer }) => (
            <CollapseQuestion
              key={question}
              question={question}
              answer={answer}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CommonQuestions
