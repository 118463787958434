/* eslint-disable prettier/prettier */
import React from 'react'
import type { FC } from 'react'
import { Button } from '@vtex/store-ui'
import { useLikedPosts } from 'src/hooks/useLikedPosts'
import LikeIcon from 'src/components/icons/LikeIcon'

interface Props {
  postTitle: string
}

const LikeButton: FC<Props> = ({ postTitle }) => {
  const { isInLikedPosts, addToLikedPosts, removeFromLikedPosts } =
    useLikedPosts()

  const handleLike = async () => {
    isInLikedPosts?.(postTitle)
      ? removeFromLikedPosts(postTitle)
      : addToLikedPosts(postTitle)
  }

  return (
    <Button type="button" onClick={() => handleLike()}>
      {postTitle && isInLikedPosts?.(postTitle) ? (
        <LikeIcon filled />
      ) : (
        <LikeIcon />
      )}
    </Button>
  )
}

export default LikeButton
