import React from 'react'
import { Box, Flex, Image } from '@vtex/store-ui'
import RichText from 'src/components/ui/RichText'
import PostCarousel from 'src/components/posts/Carousel'
import PostInfo from 'src/components/posts/PostInfo'
import type { PilarPageQueryQuery } from 'src/pages/{CmsPilarPage.postConfiguration__postConfiguration__seo__slug}/__generated__/PilarPageQuery.graphql'

import * as styles from './styles'

interface Props {
  data: PilarPageQueryQuery
}

const AboveTheFold = ({ data }: Props) => {
  const sections = data.cmsPilarPage?.sections

  const carouselImages = sections?.filter(
    (section) => section.name === 'Carousel'
  )

  const postConfigurations =
    data.cmsPilarPage?.postConfiguration?.postConfiguration

  const paragraphs = sections?.filter(
    (section) => section.name === 'Post Paragraph'
  )

  return (
    <Box sx={styles.container}>
      {carouselImages && (
        <PostCarousel banners={carouselImages[0].data.allItems} />
      )}

      <PostInfo postConfigurations={postConfigurations} />

      {postConfigurations?.description && (
        <RichText
          richText={postConfigurations?.description}
          styles={styles.descriptionText}
        />
      )}

      <Flex sx={styles.paragraphsContainer}>
        {paragraphs?.map((paragraph) => {
          const { text, largeText } = paragraph.data
          const largeTextData = JSON.parse(largeText)

          return (
            <Box key={text}>
              <Flex sx={styles.paragraph}>
                {text && (
                  <Box sx={styles.textWrapper}>
                    <RichText richText={text} />
                  </Box>
                )}
                {paragraph.data.image.source.srcSet && (
                  <Box sx={styles.imageWrapper}>
                    <Image
                      src={paragraph.data.image.source.srcSet}
                      alt={paragraph.data.image.alt ?? ''}
                      title={paragraph.data.image.alt ?? ''}
                    />
                  </Box>
                )}
              </Flex>
              {largeText && largeTextData.blocks[0]?.text !== '' && (
                <Box sx={styles.largePostText}>
                  <RichText richText={largeText} />
                </Box>
              )}
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}

export default AboveTheFold
