import React from 'react'
import { Helmet } from 'react-helmet'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { View } from 'src/components/ui/View'
import Layout from 'src/components/Layout'
import AboveTheFold from 'src/views/posts/AboveTheFold'
import BelowTheFold from 'src/views/posts/BelowTheFold'
import Seo from 'src/views/posts/Seo'

import type { PilarPageQueryQuery } from './__generated__/PilarPageQuery.graphql'

type Props = PageProps<PilarPageQueryQuery>

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: {
    component: BelowTheFold,
  },
}

const Page: FC<Props> = (props) => {
  const canonicalMetatag = `https://www.avon.com.br/${props.location.pathname}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')
    .replace(/\/$/, '')

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalMetatag} />
      </Helmet>
      <Layout>
        <View {...ViewComponents} data={props} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query PilarPageQuery($id: String!) {
    cmsPilarPage(id: { eq: $id }) {
      postConfiguration {
        postConfiguration {
          date
          description
          title

          seo {
            description
            slug
            title
            titleTemplate
          }
        }
      }

      sections {
        name
        data
      }
    }
  }
`

export default Page
