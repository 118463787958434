import type { SxStyleProp } from '@vtex/store-ui'

export const container: SxStyleProp = {
  maxWidth: ['100vw', '95vw'],
  margin: ['0 auto', '1.5rem auto 0'],
  fontFamily: 'Zona Pro, sans-serif',

  '.gatsby-image-wrapper': {
    maxHeight: 'inherit',

    '@media screen and (max-width: 720px)': {
      position: ['initial !important'],
    },
  },
}

export const descriptionText: SxStyleProp = {
  fontSize: ['1rem'],
  lineHeight: ['1.375rem', '1.5625rem'],
  padding: ['0 1rem', 'initial'],
}

export const paragraphsContainer: SxStyleProp = {
  gap: ['1rem', '0'],
  flexDirection: 'column',
}

export const paragraph: SxStyleProp = {
  gap: ['0', '2.5rem'],
  mt: ['1rem', '4.375rem'],
  fontSize: ['', '18px'],
  lineHeight: ['', '1.5625rem'],

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    gap: '0',
    mt: '1rem',
  },

  '&:nth-child(even)': {
    flexDirection: ['column', 'initial'],

    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },

  '&:nth-child(odd)': {
    flexDirection: ['column', 'row-reverse'],

    '@media screen and (min-width: 768px) and (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },
}

export const textWrapper: SxStyleProp = {
  order: ['2', 'initial'],
  width: ['100%', '60%'],
  padding: ['0 1rem', 'initial'],

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    order: 2,
    width: '100%',
    padding: '0 1rem',
  },

  h2: {
    margin: ['0 0 0.938rem', '1.875rem 0 0.938rem'],
    fontSize: ['1.5rem', '2.1875rem'],
    lineHeight: ['2.313rem', '2.3125rem'],
  },

  h3: {
    margin: ['0 0 0.938rem', '1.875rem 0 0.938rem'],
    fontSize: ['', '1.75rem'],
    lineHeight: ['', '1.875rem'],
  },

  h4: {
    margin: ['0 0 0.938rem', '1.875rem 0 0.938rem'],
  },

  p: {
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.375rem',
    margin: '0.625rem 0',
  },

  ul: {
    margin: '0.875rem 0 0 1.125rem',
  },
}

export const imageWrapper: SxStyleProp = {
  flex: '1',
  order: ['1', 'initial'],
  padding: ['0 1rem', 'initial'],

  img: {
    width: '100%',
    height: 'fit-content',
  },
}

export const largePostText: SxStyleProp = {
  width: ['100%', '98%'],
  padding: ['0 1rem', 'initial'],

  h2: {
    margin: ['0 0 0.938rem', '1.875rem 0 0.938rem'],
    fontSize: ['1.5rem', '2.1875rem'],
    lineHeight: ['2.313rem', '2.3125rem'],
  },

  h3: {
    margin: ['0 0 0.938rem', '1.875rem 0 0.938rem'],
    fontSize: ['', '1.75rem'],
    lineHeight: ['', '1.875rem'],
  },

  h4: {
    margin: ['0 0 0.938rem', '1.875rem 0 0.938rem'],
  },

  p: {
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '1rem',
    lineHeight: '1.375rem',
    margin: '0.625rem 0',
  },

  ul: {
    margin: '0.875rem 0 0 1.125rem',
  },
}
