import { GatsbySeo } from 'gatsby-plugin-next-seo'
import React from 'react'
import type { PilarPageQueryQuery } from 'src/pages/{CmsPilarPage.postConfiguration__postConfiguration__seo__slug}/__generated__/PilarPageQuery.graphql'

interface Props {
  data: PilarPageQueryQuery
}

const Seo = ({ data }: Props) => {
  const postConfigurations =
    data.cmsPilarPage?.postConfiguration?.postConfiguration

  return (
    <GatsbySeo
      title={postConfigurations?.seo?.title as string}
      description={postConfigurations?.seo?.description as string}
    />
  )
}

export default Seo
