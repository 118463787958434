import React from 'react'

export const questions = [
  {
    question: 'Quais são as formas de pagamento?',
    answer: (
      <>
        A Avon oferece as seguintes opções de pagamento: <br />
        <strong>Cartão de Crédito:</strong> você pode pagar com os principais
        cartões de crédito do mercado. Pagamento à vista ou em até 6x (com
        parcelas mínimas de R$10,00).
        <br />
        <strong>Boleto Bancário:</strong> esta opção cria um boleto bancário
        para pagar a sua compra e não permite parcelamento. Para realizar o
        pagamento, imprima o boleto no final do processo de compra e pague em
        qualquer agência bancária ou pela Internet até a data de vencimento
        indicada no boleto. O boleto tem vencimento de três dias úteis.
      </>
    ),
  },
  {
    question: 'Como sei que o pagamento do meu pedido deu certo?',
    answer:
      'Você receberá um e-mail automaticamente. Seu e-mail será sempre atualizado a cada novidade do seu pedido, lembre-se sempre de adicionar o domínio @avonstore.com.br como confiável.',
  },
  {
    question: 'É preciso me cadastrar no site antes de comprar?',
    answer:
      'Não é necessário se cadastrar antes de comprar: basta clicar no botão “Finalizar Compra”, e o sistema solicitará seu e-mail. Assim que inserir, abrirá uma página para inserir seus dados pessoais, dados de entrega e dados para a forma de pagamento, tudo bem simples e rápido para não tomar muito o seu tempo!',
  },
  {
    question: 'Qual o valor do frete?',
    answer:
      'O valor do seu frete é calculado de acordo com o peso e a dimensão dos produtos que você escolheu e também do endereço de entrega. Para saber qual será o valor é só informar o seu CEP no carrinho e pedir para calcular!!',
  },
]
