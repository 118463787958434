import type { SxStyleProp } from '@vtex/store-ui'

export const commonQuestionContainer: SxStyleProp = {
  maxWidth: '90rem',
  mt: ['3.5rem', '4.75rem'],
  pb: '8.375rem',
  justifyContent: 'center',
  alignItems: 'center',
  mx: 'auto',
  color: '#000',
}

export const commonQuestionWrapper: SxStyleProp = {
  flexDirection: 'column',
  alignItems: 'center',

  h4: {
    padding: ['0 1rem', 'initial'],
    fontSize: ['1.5rem', '2rem'],
    lineHeight: ['3.375rem', '2.375rem'],
  },
}

export const commonQuestionBox: SxStyleProp = {
  flexDirection: 'column',
  width: ['85vw', '50vw'],
  maxWidth: '43.75rem',
  mt: '0.875rem',

  '@media screen and (min-width: 768px) and (max-width: 1024px)': {
    width: '80vw',
  },

  '@media screen and (min-width: 1920px)': {
    maxWidth: '63.75rem',
  },

  '@media screen and (min-width: 2400px)': {
    maxWidth: '83.75rem',
  },
}

export const collapseContainer: SxStyleProp = {
  background: '#F6F6F6',
  borderRadius: '0.875rem',
  marginBottom: '1.125rem',
  padding: ['0 0.75rem', '0 1.25rem'],

  '.ReactCollapse--collapse': {
    background: 'none',
  },

  '.ReactCollapse--content': {
    border: 'none',
  },
}

export const collapseTitle: SxStyleProp = {
  fontFamily: 'Zona Pro, sans-serif',
  fontSize: ['1rem'],
  lineHeight: ['1.375rem', 'unset'],
  fontWeight: 'bold',
  padding: '1rem 0.5rem 0.75rem 0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
}

export const collapseTitleWrapper: SxStyleProp = {
  width: ['90%', 'initial'],
}

export const collapseText: SxStyleProp = {
  padding: '0 0.5rem 1rem',
  fontFamily: 'Zona Pro, sans-serif',
  fontSize: ['0.875rem', '0.875rem'],
}
