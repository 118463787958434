import React from 'react'

const TwitterRounded = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g
        id="Group_10471"
        data-name="Group 10471"
        transform="translate(-1144 -575)"
      >
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="15"
          cy="15"
          r="15"
          transform="translate(1144 575)"
          fill="#00aced"
        />
        <path
          id="Path_7162"
          data-name="Path 7162"
          d="M-3776.075,851.9l2.679,1.624s2.558-1.462,3.126-1.827,2.233-1.624,3.694-1.664,4.181-.2,4.385,0a6.732,6.732,0,0,1,1.1.934,5.554,5.554,0,0,1,.69,2.558c.041,1.1.528,1.786,0,3.045s-.163,1.786-.934,3.329a12.345,12.345,0,0,1-1.583,2.882,4.5,4.5,0,0,1-2.233,1.786c-1.137.325-.163.447-3.329.771s-3.126.447-4.912.528-1.827.65-3.37,0-2.355-.162-2.436-1.543.041-1.218,0-3.37-.041-2.882,0-4.385a7.8,7.8,0,0,1,.568-3.613c.568-1.137.122-1.056.974-1.259A2.414,2.414,0,0,1-3776.075,851.9Z"
          transform="translate(4929 -268)"
          fill="#fff"
        />
        <path
          id="twitter"
          d="M0,0V20.166H20.166V0ZM15.986,7.254q.007.178.007.357A8.682,8.682,0,0,1,2.672,14.948h0A5.851,5.851,0,0,0,7.143,13.67a3,3,0,0,1-2.8-2.127,2.787,2.787,0,0,0,1.344-.056A2.9,2.9,0,0,1,3.28,8.463,3.129,3.129,0,0,0,4.6,8.855c-2.323-2.044-.868-4.031-.868-4.031A9.173,9.173,0,0,0,9.98,7.984a3.046,3.046,0,0,1,5.191-2.771c.154-.043.3-.09.446-.137A10.575,10.575,0,0,0,17.1,4.46,3.227,3.227,0,0,1,15.77,6.15l0,0h.005a7.041,7.041,0,0,0,1.723-.447A6.823,6.823,0,0,1,15.986,7.254Z"
          transform="translate(1148.917 579.866)"
          fill="#00aced"
        />
      </g>
    </svg>
  )
}

export default TwitterRounded
