import React from 'react'

interface Props {
  filled?: boolean
}

const LikeIcon = ({ filled }: Props) => {
  return filled ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="noun-like-5508775"
      width="27.255"
      height="23.495"
      viewBox="0 0 27.255 23.495"
    >
      <path
        id="Path_7161"
        data-name="Path 7161"
        d="M-3869.033,852.508h5.436l.739,1h.475l1.267-1.689,3.8-4.169,1.214-.9h1.636l1.161,1.267v1.319l-.211,2.9-.475.792.475.686h2.48l4.539.264,1.319.422.264,1-.792,3.747-1.794,7.178-1.108,1.636-1.953.633h-9.236l-1.794-.633-.792-.475-1.531,1.742h-2.9l-3.114-.211-1-.844V853.51l1-1Z"
        transform="translate(3871.598 -846.202)"
        fill="#ec288a"
      />
      <path
        id="Path_7159"
        data-name="Path 7159"
        d="M152.5,222.6h4.638a2.5,2.5,0,0,0,2.271-1.463,10.259,10.259,0,0,0,4.436,1.012h7.184a3.777,3.777,0,0,0,3.672-2.864l2.475-9.9a2.71,2.71,0,0,0-2.629-3.368H168.46a.261.261,0,0,1-.237-.369l.688-1.509a3.556,3.556,0,0,0-3.236-5.03,2.107,2.107,0,0,0-1.605.738l-4.959,5.815a2.488,2.488,0,0,0-1.974-.983H152.5a2.5,2.5,0,0,0-2.5,2.5V220.1a2.5,2.5,0,0,0,2.5,2.5Zm12.434-22.018a.973.973,0,0,1,.742-.341,2.42,2.42,0,0,1,2.2,3.424l-.688,1.509a1.4,1.4,0,0,0,1.271,1.975h6.081a1.575,1.575,0,0,1,1.528,1.957l-2.475,9.9a2.643,2.643,0,0,1-2.568,2H163.84a9.114,9.114,0,0,1-4.19-1.026l-.013-13.2Zm-13.8,6.593a1.367,1.367,0,0,1,1.363-1.363h4.638a1.367,1.367,0,0,1,1.365,1.363V220.1a1.367,1.367,0,0,1-1.363,1.363H152.5a1.367,1.367,0,0,1-1.363-1.363Z"
        transform="translate(-149.998 -199.103)"
      />
      <path
        id="Path_7160"
        data-name="Path 7160"
        d="M215.969,844.885h4.542a.568.568,0,1,0,0-1.135h-4.542a.568.568,0,1,0,0,1.135Z"
        transform="translate(-213.421 -824.229)"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="noun-like-5508775"
      width="27.255"
      height="23.495"
      viewBox="0 0 27.255 23.495"
    >
      <path
        id="Path_7159"
        data-name="Path 7159"
        d="M152.5,222.6h4.638a2.5,2.5,0,0,0,2.271-1.463,10.259,10.259,0,0,0,4.436,1.012h7.184a3.777,3.777,0,0,0,3.672-2.864l2.475-9.9a2.71,2.71,0,0,0-2.629-3.368H168.46a.261.261,0,0,1-.237-.369l.688-1.509a3.556,3.556,0,0,0-3.236-5.03,2.107,2.107,0,0,0-1.605.738l-4.959,5.815a2.488,2.488,0,0,0-1.974-.983H152.5a2.5,2.5,0,0,0-2.5,2.5V220.1a2.5,2.5,0,0,0,2.5,2.5Zm12.434-22.018a.973.973,0,0,1,.742-.341,2.42,2.42,0,0,1,2.2,3.424l-.688,1.509a1.4,1.4,0,0,0,1.271,1.975h6.081a1.575,1.575,0,0,1,1.528,1.957l-2.475,9.9a2.643,2.643,0,0,1-2.568,2H163.84a9.114,9.114,0,0,1-4.19-1.026l-.013-13.2Zm-13.8,6.593a1.367,1.367,0,0,1,1.363-1.363h4.638a1.367,1.367,0,0,1,1.365,1.363V220.1a1.367,1.367,0,0,1-1.363,1.363H152.5a1.367,1.367,0,0,1-1.363-1.363Z"
        transform="translate(-149.998 -199.103)"
      />
      <path
        id="Path_7160"
        data-name="Path 7160"
        d="M215.969,844.885h4.542a.568.568,0,1,0,0-1.135h-4.542a.568.568,0,1,0,0,1.135Z"
        transform="translate(-213.421 -824.229)"
      />
    </svg>
  )
}

export default LikeIcon
