import React, { useState } from 'react'
import type { ReactNode } from 'react'
import { Box, Heading, Text } from '@vtex/store-ui'
import { Collapse } from 'react-collapse'
import { ArrowDownIcon } from 'src/components/icons'

import * as styles from './styles'

interface CollapseQuestionType {
  question: string
  answer: ReactNode
}

const CollapseQuestion = ({ question, answer }: CollapseQuestionType) => {
  const [isOpened, setIsOpened] = useState(true)

  return (
    <Box onClick={() => setIsOpened(!isOpened)} sx={styles.collapseContainer}>
      <Heading as="h6" sx={styles.collapseTitle}>
        <Box sx={styles.collapseTitleWrapper}>{question}</Box>
        <Box
          sx={{
            svg: {
              width: '1.375rem',
              height: '1.375rem',
              fill: '#000',
              transform: isOpened ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.3s',
            },
          }}
        >
          <ArrowDownIcon />
        </Box>
      </Heading>
      <Collapse isOpened={isOpened}>
        <Text as="p" sx={styles.collapseText}>
          {answer}
        </Text>
      </Collapse>
    </Box>
  )
}

export default CollapseQuestion
