import type { SxStyleProp } from '@vtex/store-ui'

export const postInfoContainer: SxStyleProp = {
  padding: ['2.5rem 1rem 0', 'initial'],
}

export const postInfoTitle: SxStyleProp = {
  fontSize: ['1.5rem', '2.2rem'],
  lineHeight: ['2.063rem', '3.2rem'],
  color: '#000',
}

export const postInfoDetails: SxStyleProp = {
  margin: '1rem 0',
  gap: '1.25rem',
  alignItems: 'center',
  justifyContent: ['space-between', 'initial'],
  width: ['96%', '100%'],

  '> div': {
    display: ['flex'],
    alignItems: 'center',
    gap: '.5rem',
  },

  'div.brand-date': {
    display: 'flex',
    flexDirection: 'column',

    p: {
      fontSize: ['1rem'],
      margin: '0',
    },

    span: {
      fontSize: ['.75rem'],
    },
  },

  'div.gradientIcon': {
    background:
      'linear-gradient(180deg, rgba(238,40,139,1) 0%, rgba(125,31,179,1) 60%)',
    width: ['30px'],
    height: ['30px'],
    borderRadius: '100%',
  },

  'button, a': {
    all: 'unset',
    cursor: 'pointer',
  },

  button: {
    '&:disabled': {
      opacity: '0.5',
      cursor: 'not-allowed',
    },
  },
}
