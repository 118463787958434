import type { SxStyleProp } from 'theme-ui'

export const container: SxStyleProp = {
  position: 'relative',

  button: {
    background: 'none',
    border: 'none',
    position: 'absolute',
    top: '50%',
    zIndex: '2',

    svg: {
      color: '#7F7F7F',
      cursor: 'pointer',
    },
  },
}

export const imageContainer: SxStyleProp = {
  maxHeight: ['initial', '28.375rem'],

  '@media screen and (device-width: 768px)': {
    maxHeight: '16rem',
  },

  '@media screen and (min-width: 769px) and (max-width: 820px)': {
    maxHeight: '17rem',
  },

  '@media screen and (min-width: 821px) and (max-width: 920px)': {
    maxHeight: '19.375rem',
  },

  '@media screen and (device-width: 1024px)': {
    maxHeight: '21rem',
  },

  img: {
    height: ['19.875rem !important', 'initial !important'],
    objectFit: ['cover !important', ''],
    objectPosition: ['top', 'initial'],
    borderRadius: ['initial', '1.5rem'],
    overflow: 'hidden',

    '@media screen and (max-width: 280px)': {
      height: '13.875rem !important',
    },

    '@media screen and (min-width: 281px) and (max-width: 320px)': {
      height: '15.875rem !important',
    },

    '@media screen and (min-width: 321px) and (max-width: 360px)': {
      height: '16.875rem !important',
    },

    '@media screen and (min-width: 361px) and (max-width: 420px)': {
      height: '18.875rem !important',
    },
  },
}

export const dotsContainer: SxStyleProp = {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  bottom: ['-0.625rem', '1.25rem'],
  gap: '0.125rem',

  '> div': {
    background: '#000',
    cursor: 'pointer',
    height: '0.625rem',
    borderRadius: '0.563rem',
  },
}
